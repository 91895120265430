
import './App.css';
import React, { useState, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import {ListGroup} from "react-bootstrap";

function App() {
    const [size, setSize] = useState(36);
    const [gems, setGems] = useState(10);
    const [bet, setBet] = useState(0.01);
    var rows = [];
    for(var i = 0;i<gems;i++){

        rows.push((gems - i) / (size - i ));
    }
    console.log(rows);
    function calc(x){
        var  i =x;
        var multi = 1.0;
        while(i >= 0)
        {
            multi = multi * rows[i]
            i--;
        }
        return (1.0/multi) * 0.982;
    }
  return (

    <Container>
      <Form>
          <Form.Label>Grid Size</Form.Label>
        <Form.Control type={'text'} defaultValue={size} onChange={(e)=>{setSize(e.target.value)}}/>
          <Form.Label>Num Gems</Form.Label>
          <Form.Control type={'text'} defaultValue={gems} onChange={(e)=>{setGems(e.target.value)}}/>
          <Form.Label>Bet Size</Form.Label>
          <Form.Control type={'text'} defaultValue={bet} onChange={(e)=>{setBet(e.target.value)}}/>
      </Form>
        <Container>
            <ListGroup>
                {rows.map((val,gem)=><ListGroup.Item>{gem + 1} {gem > 0 ?  'gems' : 'gem'} - {Math.round(calc(gem) * 100)/100}x ${Math.round(calc(gem) * bet * 100)/100} </ListGroup.Item>)}
            </ListGroup>
        </Container>
    </Container>
  );
}



export default App;
